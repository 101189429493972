.root,
.backgroundContainer,
.rememberBtnContainer,
.orTextContainer,
.googleSignupBtn {
	display: grid;
}

.root {
	text-align: center;
	place-items: center;
	height: 100vh;
	background: #F7F7F7;
}

.root p {
	margin: 0;
}

.loginBtn,
.googleSignupBtn {
	width: 100%;
}

.loginForm {
	position: relative;
}

.loginForm,
.loginBtn,
.googleSignupBtn {
	border-radius: 2px;
}

.loginForm {
	width: 462px;
	padding: 60px 56px;
	background: #fff;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.04);
}

.logo {
	margin-bottom: 60px;
}

.errorMessage,
.forgotPassword,
.signupLink {
	font-weight: 500;
}

.errorMessage {
	text-align: left;
	margin-bottom: 24px;
	color: #FF5959;
}

.errorMessage,
.rememberText
.forgotPassword {
	font-size: 14px;
	line-height: 14px;
}

.inputsContainer {
	display: grid;
	gap: 12px;
}

.rememberBtnContainer {
	grid-template-columns: repeat(2, max-content) 1fr;
	gap: 8px;
	align-items: center;
	margin: 24px 0;
	line-height: 14px;
}

.rememberText {
	color: #052A33;
}

.forgotPassword {
	color: #288EA5;
	justify-self: end;
}

.loginBtn {
	font-size: 16px;
	font-weight: 600;
	line-height: 32px;
	height: auto;
	padding: 7px;
	margin-bottom: 16px;
	background: #288EA5;
}

.signupLinkContainer {
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 24px;
	color: #656565;
}

.orTextContainer {
	font-size: 12px;
	line-height: 15px;
	color: #656565;
	grid-template-columns: repeat(3, min-content);
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-bottom: 12px;
}

.line {
	width: 100px;
	height: 1px;
}

.lineLeft {
	background: linear-gradient(270deg, #E5E5E5 0%, rgba(238, 238, 238, 0) 100%);
}

.lineRight {
	background: linear-gradient(270deg, rgba(238, 238, 238, 0) 0%, #E5E5E5 100%);
}

.googleSignupBtn {
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	color: #052A33;
	height: auto;
	grid-template-columns: repeat(3, min-content);
	align-items: center;
	justify-content: center;
	gap: 16px;
	padding: 12px;
	border: 1px solid #EBEBEB;
}